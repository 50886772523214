import Helmet from "react-helmet";
import React from "react";

const TermsPage = () => (
  <>
    <Helmet
      meta={[
        {
          "http-equiv": `refresh`,
          content:
            "0;URL='https://www.apple.com/legal/internet-services/itunes/dev/stdeula/'",
        },
      ]}
    />
  </>
);

export default TermsPage;
